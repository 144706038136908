<script>
import "./plugins/select2.css"
import "./plugins/select2.min.js"

var $ = window.$;

export default {
	name: "Select2",
	props: {
		options:[Array, Object],
		value:[String, Number, Array],
		object:Array,
		selectClass:String,
		multiple:Boolean,
	},
	computed:{
		page(){ return this.$root.page },
	},
	mounted: function () {
		$(this.$el)
			.select2({
				dropdownCssClass: this.selectClass,
				containerCssClass: this.selectClass,
				allowClear: false,
				escapeMarkup: function(m) { return m; }
			})
			.val(this.value)
			.trigger('change')
			// emit event on change.
			.on('change', (resp) => {
				resp.val = this.multiple ? $(this.$el).val() : resp.currentTarget.value;

				this.$emit('change', resp)
				this.$emit('input', (this.multiple ? $(this.$el).val() : resp.currentTarget.value));
				if($(this.$el).closest("form").length) $(this.$el).valid();
			});
	},
	methods:{
		optValue: function(v){
			if(this.object){
				if(typeof this.object[1] == "function"){
					return this.object[1](v);
				}
				var obj = this.object[1].split("+");
				if(obj[1]) return v[obj[0]]+" "+v[obj[1]];
			}
			return this.object?v[this.object[1]]:v
		}
	},
	watch: {
		value: function (value) {
			$(this.$el).val(value).select2({ escapeMarkup: function(m) { return m; } });
		},
		options: function(){
			setTimeout(()=>{
				$(this.$el).val(this.value).select2({
					escapeMarkup: function(m) { return m; }
				})
			},10)
		}
	},
	destroyed: function () {
    $(this.$el).off().select2('destroy');
  }
}
</script>

<template>
  <select :multiple="multiple" v-bind="page.validation('bu_level')">
    <slot></slot>
    	<option v-for="(v,k) in options" :disabled="v.pc_parent_id == 0" :value="object?v[object[0]]:k" :key="k">{{optValue(v)}}</option>
  </select>
</template>